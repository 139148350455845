import { ValidationObserver, ValidationProvider } from 'vee-validate';
import createPlugin from '@shared/plugins/validate';
import defaultRules from '@shared/plugins/validate/rules';
import ValidationErrors from '@shared/plugins/validate/components/ValidationErrors.vue';
import { countryWritingPatterns, apostrophePattern, hyphenPattern, spacePattern, specialCharactersPattern } from '@shared/utils/regexUtils';

/**
 * personal Name에서 사용되는 정규식 체크
 * @param {string} name - 이름
 * @param {number} min - 최소 입력 값
 * @param {number} max - 최대 입력 값
 * @param {number} countryNumber - countryNumber
 * @returns {boolean}
 */
const validatePersonalName = ({ name, min = 0, max = 0, countryNumber = 0 }) => {
  const minMaxPattern = (Number(min) || Number(max)) ? `{${min},${max}}` : '+';
  const countryPattern = countryNumber ? (countryWritingPatterns.find(p => p.key === Number(countryNumber))?.value ?? '') : '';
  return new RegExp(`^[A-Za-z${hyphenPattern}${spacePattern}${apostrophePattern}${countryPattern}]${minMaxPattern}$`).test(name);
};

const rgLimit = ({ value, limit }) => {
  if (!value || isNaN(value)) value = 999999999;
  if (!limit || isNaN(limit)) limit = 999999999;
  return +value <= +limit;
};

/**
 * @param {ServiceManager} services
 * @returns {{}}
 */
// eslint-disable-next-line no-unused-vars
export const makeRules = services => ({
  ...defaultRules,
  requiredNone: defaultRules.required,
  minMaxNone: defaultRules.minMax,
  minMaxAmount: defaultRules.minMax,
  requiredSelectNone: defaultRules.requiredSelect,
});

export default {
  install(Vue, { services }) {
    const { validate, extend, checkValidate } = createPlugin();
    // noinspection JSUnresolvedVariable
    const rules = makeRules(services);
    extend(rules);
    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);
    Vue.component('ValidationErrors', ValidationErrors);
    Vue.prototype.$validate = validate;
    Vue.prototype.$checkValidate = checkValidate;

    Vue.prototype.$getError = async function (validator, errorCallback) {
      try {
        await Vue.prototype.$validate(validator, errorCallback);
        return null;
      } catch (err) {
        return err;
      }
    };
  }
};
