import { Service } from '@/services/Service';
import RouterProxy from '@shared/modules/RouterProxy';
import { log } from 'shared/utils/logUtils';

/**
 * @implements {ProxyManager}
 */
class SsoServiceManager extends Service {
  constructor({ store = null, cookies = null } = {}) {
    super({ store, cookies });
  }

  async mounted(app, route){}
  async init(url, host, queries, store) {
    try {
      queries.randomGuid && log({message: 'queries.randomGuid'}, queries.randomGuid);
      this.store.commit('setRandomGuid', queries.randomGuid);
      this.store.commit('setIsSso', host.includes('sso')); // [ean] main domain으로 루트페이지 노출 처리 테스트 필요 시 ssr build 후 확인 필요
    } catch(e) {
      log({ logLevel: 'ERROR', message: 'server side error'}, e);
    }
    return true;
  }

  setRouter(router) {
    this.router = new RouterProxy(router);
    this.router.setRouter(router);
  }
}

export const servicePlugin = {
  install(Vue, { services }) {
    Vue.prototype.$services = services;
  }
};

const createServiceManager = ({ store, cookies, router }) => new SsoServiceManager({ store, cookies });

export default createServiceManager;
