import Vue from 'vue';
import Vuex from 'vuex';
import { store as browser } from '@shared/modules/Browser';
import user from './user.store';

Vue.use(Vuex);
export default () => new Vuex.Store({
  state: {
    hideAction: false,
    resendCloseSecond: 0,
    resendSumSecond: 0,
    randomGuid: null,
    isSso: null,
    mandatoryList: ['clientDeviceType', 'clientDeviceIdNew', 'brandId', 'schemeUrl'],
  },
  mutations: {
    async setResendCloseSecond(state, sec) {
      state.resendCloseSecond = sec;
    },
    async setResendSumSecond(state, sec) {
      state.resendSumSecond = sec;
    },
    setRandomGuid(state, v) {
      state.randomGuid = v;
    },
    setIsSso(state, v) {
      state.isSso = v;
    }
  },
  modules: {
    browser: browser(),
    user: user(),
  },
});