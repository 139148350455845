<template>
  <div id="app">
    <div class="contents-wrapper">
        <router-view />
    </div>
  </div>
</template>

<script>

export default {
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { vmid: 'favicon', content: '' },
        { vmid: 'title', content: '' },
        { vmid: 'description', content: '' },
        { vmid: 'keywords', content: '' },
        { vmid: 'image', content: '' },
      ],
    };
  },
  async mounted() {
    console.log('VERSION :::::::::::::::: ', process.env.VUE_APP_VERSION);
    document.dispatchEvent(new Event('render-event'));
  },
};
</script>

<style lang="less">
@import "~@/less/common.less";
#app:not(.promotion) {.bgc(#171717);
  .btn-holder { .rel() ; .pt(20); .mb(40);
    .error { .abs(); .lt(50%, -10); .t-xc(); .c(@c-red;) }
  }
  .contents-wrapper { .c(white); .crop(); .min-h(var(--innerHeight));
    .contents-holder { .p(40, 28, 0); .-box(); .tl(); .max-w(600); .mh-c(); }
  }
  @media (@tl-up) {
    .contents-wrapper {
      .contents-holder { .ph(0);
        &.tracking { .max-w(1000);}
      }
    }
  }
  &.white { .bgc(white);
    .contents-wrapper {
      .contents-holder { .max-w(none); .p(0); }
    }
  }
}
</style>
