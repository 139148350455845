import qs from 'qs';
import _ from 'lodash';

/**
 * 기존 url 에 query string 을 추가함
 * @param {string} url
 * @param {object} query
 * @param {boolean?} trim
 * @returns {string}
 */
export const appendQuery = (url, query, trim = false) => {
  return url + (url.indexOf('?') > 0 ? '&' : '?') + qs.stringify(trim ? _.pickBy(query) : query);
};

/**
 * @param {string} query
 * @returns {object}
 */
export const parseQuery = query => {
  const result = {};
  if (!query || query.indexOf('?') === -1) return result;
  const q = query.split('?')[1];
  const arr = q.split('&');
  arr.forEach(set => {
    if (set.indexOf('=') > -1) {
      const pair = set.split('=');
      result[pair[0]] = decodeURIComponent(pair[1]);
    }
  });
  return result;
};

export const touchUrl = url => new Promise(resolve => {
  const script = document.createElement('script');
  script.src = url;
  script.addEventListener('load', resolve);
  document.body.appendChild(script);
});

/**
 * eq / and로 연결되는 q 를 반환함
 * @param {Object} query
 * @returns
 */
export const eqLikeLink = query => {
  const likeKeys = ['title'];
  return Object.entries(query).reduce((acc, cur) => {
    const [k, v] = cur;
    if (!v || typeof v === 'object') return acc;
    else {
      const link = likeKeys.includes(k) ? 'like' : 'eq';
      return [...acc, `${k} ${link} ${v}`];
    }
  }, []).join(' and ');
};

/**
 * like / and로 연결되는 q 를 반환함
 * @param {Array} list
 * @returns
 */
export const likeLink = (key, list = []) => {
  return list.map((v) => `${key} like ${v}`).join(' and ');
};

/**
 * url 여부 검사
 * @returns {Boolean} isUrl
 */
export const isUrl = value => /^http[s]?:\/\/([\S]{3,})/i.test(value);

export const getMainDomain = (host) => {
  const domainParts = host.split('.');
  // 서브도메인 규칙을 정규 표현식으로 정의
  const subDomainPatterns = /(?:\.([^.]+))?\.([^.]+)\.(co\.kr|co\.uk|or\.kr|ac\.kr|com|net|eu|de|be|nl|ro|ca|biz)$/;
  const match = host.match(subDomainPatterns);
  if (match) {
    const secondLevelDomain = match[2];
    const topLevelDomain = match[3];
    return `${secondLevelDomain}.${topLevelDomain}`;
  }

  return domainParts.slice(-2).join('.');
}