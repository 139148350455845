<template>
  <GGPassTncContainer cookie-policy page-title="Cookie Policy">
    <pre>
<h3>This page is all about the GGPass cookie policy.</h3>
Like most websites, we use cookies to a limited extent to enhance the user experience and provide customization and useful features to our website and products. We also use standard analytics software to allow us to see how our customers are interacting with the site in aggregate. Since we are able to collect this data on you, we want to be as clear as possible about how we collect the data, what we collect and most importantly, what we do with it.

<h3>So, what are ‘cookies’?</h3>So, what are ‘cookies’? Well, a ‘cookie’ is a small piece of information stored on your web browser which allows your web browser to remember specific information about a website or webpage.

This ‘cookie’ provides us with useful information related to visitors to our website such as, if you’re a unique visitor or if you have been to our website before. The time you spent visiting our website and which particular page you spent the longest time on. This allows us to calculate the average length of time users spend on our website and the manner in which you found your way to our website, via a search engine or from a direct link. With this information, we can see where our website is working and where it is not. 

<h3>You control the data we have</h3>We recognize that personal information is valuable and needs to be treated with care. We will NEVER sell it to anyone.

You can configure your browser settings according to your wishes and, for example, refuse to accept third-party cookies or all cookies, only allow them in individual cases or activate the automatic deletion of cookies when closing the browser.

We recommend that you regularly delete your cookies and browser history manually. For more information on cookies and how to make browser settings in this regard, please visit www.allaboutcookies.org/manage-cookies. Further information on how to disable cookies can be obtained by referring to either your Operating Systems Manual or your Browser Guide, or by contacting your Internet Service Provider. However, given that GGPass sometimes may use cookies, you may not be able to take full advantage of our Site if you do disable them.

With cookies disabled, the Site may be restricted and disallow you from maximizing your product experience and in some cases may disallow you from taking part completely.  For example, with cookies blocked the website will forget your login information producing an inconvenient user experience requiring that you enter the same information multiple times. Unblocking cookies from your web browser will allow you to fully enhance the brand experience.

<h3>The cookies we use</h3>We use several cookies on our site, we use these cookies to:
Run functionality on site optimally by tracking your activity and making sure the content and data you see is correct.
Remember browsing activity, browser used, device type to present you with the content relevant for you.
Provide you with correct information depending on your entry point on site, such as through a search engine or affiliate network.</pre>
  </GGPassTncContainer>
</template>

<script>
import GGPassTncContainer from '@/views/pages/ggpass-tnc/GGPassTncContainer.vue';
export default {
  name: 'CookiePolicy',
  metaInfo() {
    return {
      title: 'Cookie Policy',
      meta: [
        { vmid: 'og:type', property: 'og:type', content: 'website' },
        { vmid: 'og:image', property: 'og:image', content: 'https://ssl.gg-global-cdn.com/game/platform-front/img/widget/ggpass-cookie-policy-og.png' },
      ],
    };
  },
  components: { GGPassTncContainer },
}
</script>

<style lang="less">
@import '~@/less/proj.less';
[cookie-policy] {
  .key-visual { .bg('https://ssl.gg-global-cdn.com/game/platform-front/img/widget/ggpass-cookie-policy-key-visual.png'); }
}
</style>
