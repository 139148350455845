import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import { i18n } from '@/plugins/i18n';

import Main from '@/views/pages/Main.vue';
import SSO from '@/views/pages/SSO.vue';
import SsoPost from '@/views/pages/SsoPost.vue';
import SsoClose from '@/views/pages/SsoClose.vue';
import LanguageRoot from '@/views/LanguageRoot.vue';
import NotFound from '@/views/pages/NotFound.vue';
import Callback from '@/views/pages/Callback.vue';
import CookiePolicy from '@/views/pages/ggpass-tnc/CookiePolicy.vue';
import PrivacyPolicy from '@/views/pages/ggpass-tnc/PrivacyPolicy.vue';
import TermsAndConditions from '@/views/pages/ggpass-tnc/TermsAndConditions.vue';

Vue.use(VueRouter);
Vue.use(Meta);

const notFound = [{ path: '/not-found', name:'NotFound', component: NotFound }];

const getRoutes = store => {
  const ssoRoute = isSso => [
    {
      path: '/',
      name: isSso ? 'SSO' : 'Main',
      component: isSso ? SSO : Main,
    },
    {
      path: '/callback',
      name: 'Callback',
      component: Callback,
    },
    {
      path: 'sso-post',
      name: 'SsoPost',
      component: SsoPost,
    },
    {
      path: 'close',
      name: 'SsoClose',
      component: SsoClose,
    },
    {
      path: 'terms-and-conditions',
      name: 'TermsAndConditions',
      component: TermsAndConditions,
    },
    {
      path: 'cookie-policy',
      name: 'CookiePolicy',
      component: CookiePolicy,
    },
    {
      path: 'privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy,
    },
    ...notFound,
  ];

  console.log('store.state.isSso', store.state.isSso);
  return ssoRoute(store.state.isSso);
};

export default async store => new VueRouter({
  mode: 'history',
  scrollBehavior: () => null,
  routes: [
    {
      path: `/:locale(${i18n.routeMatches()})?`,
      component: LanguageRoot,
      children: getRoutes(store),
      linkActiveClass: 'active',
      linkExactActiveClass: 'active',
    },
  ],
});
