<template>
  <div sso v-if="isReady">
    <div class="holder">
      <p>Please continue to log in</p>
      <ColorButton type="red" @click="back">Return to Game</ColorButton>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { parseQuery } from '@shared/utils/urlUtils';
import { sleep } from '@shared/utils/commonUtils';
import ColorButton from '@shared/components/common/ColorButton.vue';
export default {
  name: 'SSO',
  components: { ColorButton },
  data() {
    return {
      isReady: false,
      type: null,
    };
  },
  computed: {
    queryString() {
      return parseQuery(location.search);
    },
    snsType() {
      return this.queryString?.snsType;
    },
    isGoogle() {
      return this.$route.path.includes('google');
    },
    isFacebook() {
      return this.$route.path.includes('facebook');
    },
    isApple() {
      return this.$route.path.includes('apple');
    },
    ssoType() {
      return this.isGoogle ? 'GOOGLE' : this.isFacebook ? 'FACEBOOK' : 'APPLE';
    },
  },
  methods: {
    checkEnableQuery() {
      const queries = parseQuery(location.search);
      return _.every(this.$store.state.mandatoryList, o => Boolean(queries[o]));
    },
    go(type, id) {
      let cId = '';
      let scope = '';
      let locationUri = '';
      const host = location.host;
      const brandId = this.$route.query.brandId || 'GGPCOM';
      const callbackUrl = `https://${host}/callback`;
      // randomGuid 를 받아서 state 값으로 사용
      const randomGuid = this.$store.state.randomGuid || 'TEST_GUID';

      if (type.includes('google')) {
        cId = process.env.VUE_APP_SSO_GOOGLE_ID;
        scope = 'https://www.googleapis.com/auth/userinfo.email';
        locationUri = `https://accounts.google.com/o/oauth2/auth?client_id=${cId}&redirect_uri=${encodeURIComponent(callbackUrl)}&response_type=code&scope=${encodeURIComponent(scope)}&state=${randomGuid}`;
      } else if (type.includes('facebook')) {
        cId = process.env.VUE_APP_SSO_META_ID;
        scope = 'email';
        locationUri = `https://www.facebook.com/v19.0/dialog/oauth?client_id=${cId}&redirect_uri=${encodeURIComponent(callbackUrl)}&scope=${encodeURIComponent(scope)}&state=${randomGuid}`;
      } else if (type.includes('apple')) {
        const clientId = process.env.VUE_APP_ENV === 'production' && brandId.toLowerCase() === 'clubgg' ? process.env.VUE_APP_SSO_CLUBGG_APPLE_ID : process.env.VUE_APP_SSO_PLATFORM_APPLE_ID;
        locationUri = `https://appleid.apple.com/auth/authorize?response_type=code id_token&response_mode=form_post&scope=name email&client_id=${clientId}&redirect_uri=${encodeURIComponent(callbackUrl)}&state=${randomGuid}`;
      }
      locationUri && window.location.replace(locationUri);
    },
    back() {
      location.href = `${this.$route.query.schemeUrl}://ggpass-sso?error=true&code=CANCEL`;
      this.isReady = false;
    },
  },
  async mounted() {
    if(!this.checkEnableQuery()) {
      this.replaceRouteName('SsoPost', null, {error: true});
      return;
    }

    if(location.host.includes('apple')) {
      this.type = 'apple';
      this.go('apple');
    } else if(location.host.includes('google')) {
      this.type = 'google';
      this.go('google');
    } else if(location.host.includes('facebook')) {
      this.type = 'facebook';
      this.go('facebook');
    }
    await sleep(300);
    this.isReady = true;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[sso] {.rel(); .h(var(--innerHeight));
  .holder { .abs(); .lt(50%,50%); .t-xyc();
    >p { .fs(20); .mb(40); }
    [color-button] { .w(320); .fs(20); .medium(); .h(48); .br(40); }
  }
}
</style>
