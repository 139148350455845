<template>
  <div not-found>
    NOT FOUND
  </div>
</template>

<script>

export default {
  name: 'NotFound',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[not-found] { .rel(); .wh(100%, calc(var(--innerHeight) - 160px));
  [error-title] { .abs(); .lt(50%, 50%); .t-xyc();}
}
</style>