import Vue from 'vue';
import gtm from '@/plugins/tracking/gtm';

export const setGTM = (brandId, router) => {
  const env = process.env.VUE_APP_ENV;
  if (env !== 'local') {
    const key = brandId === 'GGPOKER' ? process.env.VUE_APP_GTM_GGPUK : brandId === 'WSOP' ? process.env.VUE_APP_GTM_WSOPON : process.env.VUE_APP_GTM_PUBLIC;
    Vue.use(gtm, { id: env === 'test' ? process.env.VUE_APP_GTM_TEST : key, router });
  }
};

export const trackSegment = (track, identify) => {
  if (window.analytics) {
    if (track) window.analytics.track(track);
    if (identify) window.analytics.identify(identify);
  }
};