import modal from '@shared/plugins/modal';
import toast from '@shared/plugins/toast';
import scrollAgency from '@shared/plugins/scrollAgency';
import browser from '@shared/modules/Browser';
import { NoToast } from '@shared/modules/ToastErrorHandler';
import directive from '@shared/plugins/popover/directive';
import app from '@/app';

export default app.getEntryClient({
  beforeCreate: async (Vue, store, router, services) => {
    Vue.config.productionTip = false;
    Vue.use(modal);
    Vue.use(toast);
    Vue.use(scrollAgency, { store });
    Vue.directive('popover', directive(Vue));
  },
  makeRouteErrorHandler: (services, router) => async e => {
    const { code } = e;
    if (code === 301 || code === 302) {
      if (/^https?:\/\//.test(e.to)) window.location.href = e.to;
      else await router.replace(e.to);
    }
    if (code === 404) await router.push('/404');
    if (code === 401 || code === 403) {
      services.toast.toast(['httpErrors', code], { type: 'fail' });
      if (code === 401) {}
      throw NoToast;
    }
  },
  afterCreate: (app, router, store) => {
    browser.useScrollHistory({ router, store, scrollHandler: app.$scroll });
  },
  afterMount: (app, route, store) => {
    browser.sync(store);
  },
});
