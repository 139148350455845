<template>
  <GGPassTncContainer main page-title="">
    <img src="https://ssl.gg-global-cdn.com/game/platform-front/img/widget/GGPass_LOGO_White_20241106.png" />
  </GGPassTncContainer>
</template>

<script>
import GGPassTncContainer from '@/views/pages/ggpass-tnc/GGPassTncContainer.vue';
export default {
  name: 'Main',
  metaInfo() {
    return {
      title: 'GGPass',
      meta: [
        { vmid: 'og:type', property: 'og:type', content: 'website' },
        { vmid: 'og:image', property: 'og:image', content: 'https://ssl.gg-global-cdn.com/game/platform-front/img/widget/ggpass-cookie-policy-og.png' },
      ],
    };
  },
  components: { GGPassTncContainer },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[main] {
  >.contents-body > .container > img { .w(500); .rel(); .t(-280); }
}
</style>
