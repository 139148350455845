<template>
  <div sso-close>
  </div>
</template>

<script>
export default {
  name: 'SsoClose',
  mounted() {
    window.open('', '_self').close();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
</style>