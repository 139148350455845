<template>
  <div callback />
</template>

<script>
import axios from 'axios';
import { state } from '@shared/utils/storeUtils';
export default {
  name: 'Callback',
  computed: {
    token: state('user', 'gwToken'),
  },
  methods: {
    tokenReplace(r) {
      if(r.isPc) {
        r.opAccessToken = r.accessToken;
        delete r.accessToken;
      }
      return r;
    }
  },
  async mounted() {
    let r;
    const postUrl = `https://${location.host}/callback`;
    // TODO : [ean] 실제로는 아래 로직은 에러 발생 제외하곤 타지 않는 것으로 보임. 정리 필요
    if(location.host.includes('google')) {
      r = await axios.post(postUrl, this.$route.query)
        .then(e => {
          this.replaceRouteName('SsoPost', null, this.tokenReplace(e.data));
          return true;
        })
        .catch(e => {
          this.replaceRouteName('SsoPost', null, {error: true, ...e?.data});
        });
    } else if(location.host.includes('facebook')) {
      r = await axios.post(postUrl, this.$route.query)
        .then(e => {
          this.replaceRouteName('SsoPost', null, this.tokenReplace(e.data));
          return true;
        })
        .catch(e => {
          this.replaceRouteName('SsoPost', null, {error: true, ...e?.data});
        });
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[callback] { .c(white); }
</style>