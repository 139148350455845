import ApiConnection from '@/modules/ApiConnection';

export class Service {
  static cnt = 0;

  id;

  /** API */
  #api;
  #userApi;
  #passApi;
  #promoApi;
  #npDevApi;
  #channelingApi;

  constructor({ store = null, cookies = null }) {
    this.id = Service.cnt++;
    this.store = store;
  }

  get passApi() {
    if (!this.#passApi) this.#passApi = new ApiConnection(this.#gateway, this, this.applyGgpass.bind(this));
    return this.#passApi;
  }

  get #gateway() {
    return process.env.VUE_APP_GATEWAY_API;
  }

  applyGgpass(config) {
    const c = config || {};
    if (!c.headers) c.headers = {};
    return c;
  }
}