<template>
  <GGPassTncContainer terms-and-conditions page-title="Terms & Conditions">
    <pre>
          <h3>TERMS & CONDITIONS OF USE</h3>
Date of Last Revision: 20241022

Version 1

END USER LICENCE AGREEMENT (“EULA”) FOR GG INTERNATIONAL LIMITED: IMPORTANT – PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE PROCEEDING TO USE OUR SERVICES, AS YOUR AGREEMENT TO THEM CONSTITUTES A LEGALLY BINDING AGREEMENT. IT IS YOUR SOLE RESPONSIBILITY TO PERIODICALLY REVIEW THIS EULA AND YOUR CONTINUED USE OF THE SERVICES (AS DESCRIBED BELOW), AND ASSOCIATED SERVICES, CONSTITUTES YOUR ONGOING ACCEPTANCE OF THE TERMS CONTAINED HEREINAFTER.

These Terms and Conditions (collectively referred to as the "Terms", “Terms of Service”, "Terms of Use", "Terms and Conditions", “Conditions”, or "this Agreement") is between you ("user", “Your” or “You”) and GG International Limited ("GGPass", "us," "we," or "Company"), and governs your use of the GGPass software functionality (“Software”) and the website www.ggpass.com ("Site"). The Software and the Site may hereinafter be jointly referred to as the "Service/s" or the "GGPass Service/s". Read the following terms before using our Software and Site.

Your Privacy: We respect the privacy of our users. For more information on how your data is transferred and processed, please see our Privacy Policy, located at https://www.ggpass.com/privacy (the "Privacy Policy"). By using the Services (as described below), you consent to our collection, use and disclosure of personal data and other data as outlined therein.

Additional Terms: In addition, when using certain features through the Services, you will be subject to any additional terms applicable to such features that may be posted on or within the Services from time to time. All such terms are hereby incorporated by reference into these Terms of Service.

1. Introduction

1.1 The following Terms and Conditions as updated by us from time to time and made available here shall govern the relationship between You and Us in relation to all use of the Services.

1.2 The GGPass Services are provided by GG International Limited, a company incorporated under the laws of Isle of Man with registered address at The Hubb, Queen Victoria House, Victoria Street, Douglas, IM1 2LF, Isle of Man.

1.3 By using and/or visiting our Services or by opening an account, you agree to be bound by these Conditions, including our Privacy Policy, Cookie Policy, and any other terms and conditions applicable to our Services.

1.4 Please read these Terms carefully before accepting them. If you do not agree to be bound by these Terms, please do not use our Services. Your continued use of the Services constitutes acceptance of these Terms.

2. GGPass Services

2.1 Definition of GGPass Service:
GGPass is a service that provides Single Sign-On (SSO) functionality to enhance onboarding and account management across various platforms. This service allows you to use one set of login credentials to access multiple applications and services seamlessly. GGPass does not offer any service other than what is described in this section 2.1, and any other services received through other entities but facilitated via GGPass’ SSO functionality are provided to you independently and distinctly from the GGPass Services.

3. Changes to the Terms and Conditions

3.1 We may need to change these Terms for a number of reasons, including to comply with new laws and regulations. The most up-to-date Terms can be accessed on our website (ggpass.com), and the date on which they came into force is noted.

3.2 Where we make substantial changes to the Terms, we will notify you via email, a message in your account, or a notice on our website.

3.3 We may, at our discretion, invite You to accept the new Conditions by clicking on “yes” or “I accept”, checking a ‘tick box’ or any other similar method of confirmation by You. If You provide us with any such confirmation, or if you continue to use the Services and/or Your Account after notification under this section 3 (Changes To The Terms And Conditions), You shall, from such time, be deemed to have accepted, and be bound by, the new Terms and Conditions (including any additions, removals, substitutions). If any change is unacceptable to You, You may either cease using the Services and/or close Your Account by following the steps in paragraph 4.10 below.

3.4 It remains Your responsibility to periodically check these Conditions to ensure you continue to agree with them each time You use the Services.

3.5 By continuing to use the Service after any changes, you agree to be bound by the updated Terms and Conditions.

4. Account Opening and Security

4.1 To use the GGPass Service, you must open an account by providing the required information. For more details on how we manage your data visit our Privacy Policy. You must not create an account on behalf of another person.

4.2 In order to register, open an account and use our Services, You represent and warrant that:
You are at least 18 years of age and meet the minimum age requirement in Your local jurisdiction to contract with Us.
You will abide at all times by these Conditions and any other agreements between You and us;
You are legally able to enter into contracts;
You have not previously had your account closed by us for any reason;
You acknowledge that it is your responsibility to ensure that You understand and fully comply with any laws or regulations applicable in your own country or state.

4.3 If You do not meet the eligibility requirements set out above You are not authorised to use our Services. We reserve the right to suspend and/or close any accounts of any user that does not meet the eligibility requirements.

4.4 You are only permitted to open one account. If You have more than one account, You must immediately inform us. If we find more than one account (“Duplicate Accounts”) is registered against your personal information, we reserve the right to block or close any or all such accounts. 

4.5 You must provide accurate information when opening an account and keep your details up-to-date.

4.6 You are responsible for maintaining the confidentiality of your account details. We do not accept any liability for damages resulting from your negligence or inability to protect your login details.

4.7 We shall have the right to close, at any time, a new account opened in breach of the requirements stated herein.

4.8 You are required to enter all mandatory information requested by us. We shall not be liable for incorrect entries, including but not limited to data input error.

4.9 We may suspend or terminate Your Account immediately at our absolute discretion if You breach any of Your obligations under these Conditions. We reserve the right to claim damages caused by the breach.

4.10 If You wish to close Your account You must send a request to do so to help@ggpass.com. 

5. Data Protection

5.1 We are committed to protecting your personal data. Please refer to our Privacy Policy for more information on how we handle your data.

6. User Conduct and Restrictions

6.1 You agree to refrain from using our Services for the following purposes:
to distribute, sell, license, provide or otherwise make any services available to third parties without our consent;
to store or transmit material or information that is illegal, libelous, defamatory, obscene, fraudulent, false or infringing the ownership or intellectual property rights of any other person or otherwise harmful, unlawful or tortious, or in violation of any third party privacy rights;
in any way that is in violation of any applicable law, rule or regulation; or
to transmit viruses, malware, or other malicious code.

7. Termination

7.1 We reserve the right to suspend or terminate your account if you breach these Terms, provide false information during registration, or engage in any conduct that we determine, in our sole discretion, to be harmful to the service.

8. Intellectual Property Rights

8.1 These Conditions confer only the right to use the Services while these Conditions and applicable license(s) (if any) are in effect and they do not convey any rights of ownership in or to the Services or any of our intellectual property. All right, title and interest, including without limitation any copyright, patent, trade secret or other intellectual property right in the Services will remain our sole property. Any services provided to You under these Conditions, and other data or materials that are prepared in the performance of such Services hereunder, and all right, title and interest in the foregoing, will belong to us.

9. Indemnification

9.1.You agree to hold harmless and indemnify us and our subsidiaries, affiliates, officers, agents and employees from and against any third party claim arising from or in any way related to Your breach of these Conditions, or Your violation of any law or the rights of a third party, or Your use of the Services, including any liability or expense arising from all claims, losses, damages (actual or consequential), suits, judgments, litigation costs, and attorneys’ fees, of every kind and nature. Nothing in these Conditions shall be deemed to exclude or limit Your liability in respect of any indemnity given by You under these Conditions.

10. Limitation Of Liability

10.1 We do not warrant that our operations shall be uninterrupted or error-free. We shall provide our Services on an “as is” and “as available” basis without warranty of any kind, whether expressed or implied, including, but not limited to the implied warranties of merchantability and fitness for a particular purpose. To the maximum extent permitted by law, we exclude all Conditions, warranties, representations or other terms which may apply to the Services, whether express or implied.

10.2 We will take all measures which are industry-standard to prevent any malicious attack, including but not limited to hacking, data breaches, viruses, distributed denial-of-service attack, or other technologically harmful material that may impact you or your devices  whilst using our Services. Nonetheless, you agree that we shall not be liable for any such loss or damage caused by a third party.

10.3 We will not be liable for any loss or damage that You may suffer because of any act of God, power failure, trade or labour dispute, act, failure or omission of any government or authority, obstruction or failure of telecommunication services or networks, access delays or access interruption, data non-delivery or data misdelivery, the unauthorised use of Your security access details or any other delay or failure caused by a third party. In such an event we reserve the right to cancel or suspend our services arising from such circumstances without incurring any liability. Furthermore, we will not be responsible for the failure of any equipment, wherever located or administered, or whether under our direct control or not, that may prevent the operation of our Services or prevent You from being able to contact us.

10.4 You agree that GGPass uninvolved shall not be held liable or responsible for any claim, demand, or litigation arising between you and the services offered to you by any other party, including any other party whose services are facilitated by GGPass’ SSO functionality. By using our Services, you expressly agree to indemnify and hold harmless GGPass, its affiliates, directors, officers, employees, and agents from any and all claims, losses, liabilities, damages, costs, or expenses, including reasonable attorneys' fees, arising from or related to any dispute between you and any such other party referred to hereabove.

10.5 In the event of a dispute with a third party, you are solely responsible for pursuing and resolving such dispute independently, without involving GGPass in any capacity. We reserve the right to limit or restrict your access to our Services if such a dispute disrupts or threatens to disrupt our business operations or reputation.

11. Governing Law

11.1 These Terms shall be governed by and interpreted in accordance with the laws of Isle of Man, and any disputes arising in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of Isle of Man.

12. Miscellaneous

12.1 If, for any reason, a competent authority, or a court of competent jurisdiction finds any provision of these Conditions, or portion thereof, unenforceable, invalid, or unlawful, then the remainder of these Conditions shall continue in full force and effect and will be read to give maximum effect to the intention of the parties as reflected by its plain language.

12.2 You agree that no venture, partnership, employment, or agency relationship exists between You and us as a result of these Conditions.

12.3 Our failure to exercise any rights under these Conditions shall not institute or be deemed a waiver or forfeiture of such right or waiver or forfeiture of such rights in the future.

12.4 These Conditions constitute the entire understanding between You and us relating to the use of the Services.


<h3>Disclaimer</h3>
We will not be held responsible in any circumstances for any loss or damage that may result from the use of the Services.  We disclaim any warranty for the Services which are provided on an ‘as is’ basis.  We will endeavour to supply the Services to the highest possible standard and in a safe and secure manner, although we make no warranty that their supply will be uninterrupted or free from errors. Where any defect is notified to us we will make every effort to correct the defect in a timely manner.  We will not be liable to you where any communication or system is impacted by a delay or failure.  We reserve the right to suspend, remove or modify the Services, either temporarily or permanently, at our sole discretion and without any obligation to provide you will notice of such an action. We will not be liable for any loss incurred in this circumstance.  It is your sole responsibility to ensure that you have the legal right to use the Services from within the jurisdiction that you reside or access them from. The availability of the Services or Website in any jurisdiction should not be construed as an offer, solicitation or invitation by us to use the Services. You acknowledge that your participation in the Services is at your sole decision and that you accept the full risk of using the Services. You further acknowledge that you do not find the Services to be offensive, biased or indecent.</pre>
  </GGPassTncContainer>
</template>

<script>
import GGPassTncContainer from '@/views/pages/ggpass-tnc/GGPassTncContainer.vue';
export default {
  name: 'TermsAndConditions',
  metaInfo() {
    return {
      title: 'Terms & Conditions',
      meta: [
        { vmid: 'og:type', property: 'og:type', content: 'website' },
        { vmid: 'og:image', property: 'og:image', content: 'https://ssl.gg-global-cdn.com/game/platform-front/img/widget/ggpass-terms-and-conditions-og.png' },
      ],
    };
  },
  components: { GGPassTncContainer },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[terms-and-conditions] {
  .key-visual { .bg('https://ssl.gg-global-cdn.com/game/platform-front/img/widget/ggpass-terms-and-conditions-key-visual.png'); }
}
</style>
